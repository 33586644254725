export const menuItems = [
  {
    id: 2,
    label: "menuitems.home.text",
    icon: "uil-home-alt",
    link: "/",
    access: "dashboard",
  },

    // CLUB HEADING
    // Club level menu items should be in the 1000 id range
    // CRM = 1100, Ticketing = 1200, Financials = 1300, Cashless = 1400, Orders = 1500, Merchandising = 1600

  {
    id: 1000,
    label: "menuitems.club.text",
    isTitle: true,
    access: "customers",
  },
  {
    id: 1100,
    label: "menuitems.customers.text",
    icon: "uil-users-alt",
    access: "customers",
    subItems: [
      {
        id: 1110,
        label: "menuitems.customers.list.users",
        link: "/customers",
        parentId: 1100,
        access: "customers_users",
      },
      {
        id: 1120,
        label: "menuitems.customers.list.groups",
        link: "/customers/groups",
        parentId: 1100,
        access: "customers_users",
      },
      {
        id: 1130,
        label: "menuitems.customers.list.customers_requests",
        link: "/customers/requests",
        parentId: 1100,
        access: "customers_requests",
      },
      {
        id: 1131,
        label: "menuitems.customers.list.blocked_users",
        link: "/customers/blocked_users",
        parentId: 1100,
        access: "blocked_users",
      },
      {
        id: 1140,
        label: "menuitems.customers.list.optins",
        link: "/customers/optins",
        parentId: 1100,
        access: "customers_optins",
      },
    ],
  },
  {
    id: 1200,
    label: "menuitems.ticketing.text",
    icon: "uil-ticket",
    access: "ticketing",
    subItems: [
      {
        id: 1210,
        label: "menuitems.ticketing.list.seasontickets",
        link: "/ticketing/season_tickets",
        parentId: 1200,
        access: "ticketing_season_tickets",
      },
      {
        id: 1220,
        label: "menuitems.ticketing.list.matchtickets",
        link: "/ticketing/match_tickets",
        parentId: 1200,
        access: "ticketing_match_tickets",
      },
      {
        id: 1230,
        label: "menuitems.ticketing.list.tickets_matches",
        link: "/ticketing/tickets_matches",
        parentId: 1200,
        access: "ticketing_tickets_matches",
      },
      {
        id: 1240,
        label: "menuitems.ticketing.list.axess_setup",
        link: "/ticketing/axess_setup",
        parentId: 1200,
        access: "axess_setup",
      },
      {
        id: 1250,
        label: "menuitems.ticketing.list.season_products",
        link: "/ticketing/season_products",
        parentId: 1200,
        access: "season_products",
      },
      {
        id: 1260,
        label: "menuitems.ticketing.list.siip_setup",
        link: "/ticketing/siip_setup",
        parentId: 1200,
        access: "siip_setup",
      },
      /*
      {
           id: 1270,
           label: "menuitems.ticketing.list.tickets_match_details",
           link: "/ticketing/tickets_matches",
           parentId: 1200,
           access: "ticketing_tickets_match_details",
       },
       */
    ],
  },
  {
    id: 1300,
    label: "menuitems.financials.text",
    icon: "uil-moneybag",
    access: "financials",
    subItems: [
      {
        id: 1310,
        label: "menuitems.financials.list.transactions",
        link: "/financials",
        parentId: 1300,
        access: "financials_transactions",
      },
      {
        id: 1320,
        label: "menuitems.financials.list.stripe_payouts",
        link: "/financials/stripe_payouts",
        parentId: 1300,
        access: "stripe_payouts",
      },
    ],
  },
  {
    id: 1400,
    label: "menuitems.cashless.text",
    icon: "uil-credit-card",
    access: "cashless",
    subItems: [
      {
        id: 1410,
        label: "menuitems.cashless.list.cards_management",
        link: "/cashless",
        parentId: 1400,
        access: "cashless_cards_management",
      },
      {
        id: 1420,
        label: "menuitems.cashless.list.topup",
        link: "/topup",
        parentId: 1400,
        access: "cashless_topup",
      },
    ],
  },
  {
    id: 1500,
    label: "menuitems.orders.text",
    icon: "uil-arrow-growth",
    link: "/orders",
    access: "orders",
  },
  {
    id: 1600,
    label: "menuitems.merchandising.text",
    icon: "uil-shopping-cart-alt",
    access: "merchandising",
    subItems: [
      {
        id: 1610,
        label: "menuitems.merchandising.list.products",
        link: "/merchandising/products",
        parentId: 1600,
        access: "merchandising_products",
      },
      {
        id: 1620,
        label: "menuitems.merchandising.list.media",
        link: "/merchandising/media",
        parentId: 1600,
        access: "merchandising_media",
      },
      {
        id: 1630,
        label: "menuitems.merchandising.list.promotions",
        link: "/merchandising/promotions",
        parentId: 1600,
        access: "merchandising_promotions",
      },
    ],
  },

  // APP HEADING
  // App level menu items should be in the 2000/3000 id range
  // Teams = 2100, Fixtures = 2200, Content = 2300, Games/Fanzone = 2400, Advertising = 2500,
  // Push Notifications = 2600, Params = 2700, Terms and Conditions = 2800, Media = 2900,
  // Social = 3000, App Params = 3100

  {
    id: 2000,
    label: "menuitems.app.text",
    isTitle: true,
    access: "games",
  },
  {
    id: 2100,
    label: "menuitems.teams.text",
    icon: "uil-football",
    access: "teams",
    subItems: [
      {
        id: 2110,
        label: "menuitems.teams.list.list",
        link: "/teams/list",
        parentId: 2100,
        access: "teams_list",
      },
      {
        id: 2120,
        label: "menuitems.teams.list.squad",
        link: "/teams/squad",
        parentId: 2100,
        access: "teams_squad",
      },
      {
        id: 2130,
        label: "menuitems.teams.list.list_sport",
        link: "/teams/list_sport",
        parentId: 2100,
        access: "list_sport",
      },
    ],
  },
  {
    id: 2200,
    label: "menuitems.fixtures.text",
    icon: "uil-calendar-alt",
    access: "fixtures",
    subItems: [
      {
          id: 2210,
          label:"menuitems.fixtures.list.current",
          link:"/fixtures/current",
          parentId: 2200,
          access:"fixtures_current",
      },
      {
        id: 2220,
        label: "menuitems.fixtures.list.feature_matches",
        link: "/fixtures/featured_matches",
        parentId: 2200,
        access: "fixtures_feature_matches",
      },
      {
        id: 2230,
        label: "menuitems.fixtures.list.rounds",
        link: "/fixtures/rounds",
        parentId: 2200,
        access: "fixtures_rounds",
      },
      {
        id: 2240,
        label: "menuitems.fixtures.list.standings",
        link: "/fixtures/standings",
        parentId: 2200,
        access: "fixtures_standings",
      },
      // {
      //   id: 2250,
      //   label: "menuitems.fixtures.list.sm",
      //   link: "/fixtures/matches",
      //   parentId: 2200,
      //   access: "fixtures_sim",
      // },
      {
        id: 2260,
        label: "menuitems.fixtures.list.sm_new",
        link: "/fixtures/matches_new",
        parentId: 2200,
        access: "fixtures_sim_new",
      },
      {
        id: 2270,
        label:"menuitems.fixtures.list.competition",
        link:"/fixtures/competition",
        parentId: 2200,
        access:"fixtures_competition",
      }
    ],
  },
  {
    id: 2300,
    label: "menuitems.content.text",
    icon: "uil-rss",
    access: "news",
    subItems: [
      {
        id: 2310,
        label: "menuitems.content.list.feed",
        link: "/news/feed",
        parentId: 2300,
        access: "news_feed",
      },
      {
        id: 2320,
        label: "menuitems.content.list.videos",
        link: "/news/videos",
        parentId: 2300,
        access: "news_videos",
      },
      {
        id: 2330,
        label: "menuitems.content.list.shorts",
        link: "/news/shorts",
        parentId: 2300,
        access: "shorts",
      },
    ],
  },
  {
    id: 2400,
    label: "menuitems.games.text",
    icon: "uil-trophy",
    access: "games",
    subItems: [
      // {
      //     id: 2410,
      //     label: "menuitems.games.list.ptw",
      //     link: "/games/player_of_the_week",
      //     parentId: 2400
      // },
      {
        id: 2420,
        label: "menuitems.games.list.gts",
        link: "/games/guess_the_score",
        parentId: 2400,
        access: "games_gts",
      },
      {
        id: 2430,
        label: "menuitems.games.list.motm",
        link: "/games/man_of_the_match",
        parentId: 2400,
        access: "games_motm",
      },
      {
        id: 2440,
        label: "menuitems.games.list.leaderboard",
        link: "/games/leaderboard",
        parentId: 2400,
        access: "games_leaderboard",
      },
      {
        id: 2450,
        label: "menuitems.games.list.mini_games",
        link: "/games/mini_games",
        parentId: 2400,
        access: "games_mini_games",
      },
      {
        id: 2460,
        label: "menuitems.games.list.social",
        link: "/games/social",
        parentId: 2400,
        access: "games_social",
      },
      {
        id: 2470,
        label: "menuitems.games.list.words",
        link: "/games/words",
        parentId: 2400,
        access: "games_words",
      },
    ],
  },
  {
    id: 2500,
    label: "menuitems.advertising.text",
    icon: "uil-file-contract-dollar",
    access: "advertising",
    subItems: [
      {
        id: 2510,
        label: "menuitems.advertising.list.sponsors",
        link: "/advertising/sponsors",
        parentId: 2500,
        access: "advertising_sponsors",
      },
      {
        id: 2520,
        label: "menuitems.advertising.list.advertisements",
        link: "/advertising/advertisements",
        parentId: 2500,
        access: "advertising_advertisements",
      },
      {
        id: 2530,
        label: "menuitems.advertising.list.banner",
        link: "/advertising/banner",
        parentId: 2500,
        access: "advertising_banner",
      },
      {
        id: 2540,
        label: "menuitems.advertising.list.advertorial",
        link: "/advertising/advertorial",
        parentId: 2500,
        access: "advertising_advertorial",
      },
      {
        id: 2550,
        label: "menuitems.advertising.list.deals",
        link: "/advertising/deals",
        parentId: 2500,
        access: "advertising_deals",
      },
      {
        id: 2560,
        label: "menuitems.advertising.list.app_ads",
        link: "/advertising/app_ads",
        parentId: 2500,
        access: "advertising_app_ads",
      },
    ],
  },
  {
    id: 2600,
    label: "menuitems.notifications.text",
    icon: "uil-message",
    link: "/notifications",
    access: "notifications",
  },
  {
    id: 2700,
    label: "menuitems.application_setup.text",
    icon: "uil-cog",
    access: "app",
    subItems: [
      {
        id: 2710,
        label: "menuitems.application_setup.list.popup",
        link: "/app/popup",
        parentId: 2700,
        access: "app_popup",
      },
      {
        id: 2720,
        label: "menuitems.application_setup.list.app_params",
        link: "/app/app_params",
        parentId: 2700,
        access: "app_params",
      },
      {
        id: 2730,
        label: "menuitems.application_setup.list.app_crm_mimic",
        link: "/app/app_crm_mimic",
        parentId: 2700,
        access: "app_crm_mimic",
      },
    ],
  },
  {
    id: 2800,
    label: "menuitems.terms_and_conditions.text",
    icon: "fas fa-file-contract",
    link: "/monitor/terms_and_conditions",
    access: "terms_and_conditions"
  },
  {
    id: 2900,
    label: "menuitems.media.text",
    icon: "uil-image",
    access: "media",
    subItems: [
      {
        id: 2910,
        label: "menuitems.media.list.media_management",
        link: "/media/media_management",
        parentId: 2900,
        access: "media_management",
      },
    ],
  },
  {
    id: 3000,
    label: "menuitems.social.text",
    icon: "uil-image",
    access: "social",
    subItems: [
      {
        id: 3010,
        label: "menuitems.social.list.social_levels",
        link: "/social/social_levels",
        parentId: 3000,
        access: "social_levels",
      },
      {
        id: 3020,
        label: "menuitems.social.list.social_milestone",
        link: "/social/social_milestone",
        parentId: 3000,
        access: "social_milestone",
      },
      {
        id: 3030,
        label: "menuitems.social.list.social_xp",
        link: "/social/social_xp",
        parentId: 3000,
        access: "social_xp",
      },
    ],
  },
  {
    id: 3100,
    label: "menuitems.application.list.application_params",
    icon: "mdi-apps",
    link: "/application/params",
    access: "application_params"
  },



  // DASHBOARD HEADING
  // Dashboard level menu items should be in the 4000 id range
  // Settings = 4100, Dashboards = 4200, Documentation = 4300, Announcements = 4400

  {
    id: 4000,
    label: "menuitems.dashboard.text",
    isTitle: true,
    access: "games",
  },
  {
    id: 4100,
    label: "menuitems.application_setup.list.settings",
    icon: "uil-dashboard",
    link: "/app/settings",
    access: "settings",
  },
  {
    id: 4200,
    label: "menuitems.dashboards.text",
    icon: "uil-home-alt",
    link: "/monitor/dashboards",
    access: "dashboards"
  },
  {
    id: 4300,
    label: "menuitems.documentation.text",
    icon: "uil-file-info-alt",
    link: "/monitor/documentation",
    access: "documentation"
  },
  {
    id: 4400,
    label: "menuitems.dashboard_announcements.text",
    icon: "fas fa-scroll",
    link: "/monitor/dashboard_announcements",
    access: "dashboard_announcements"
  },


  // MONITOR HEADING
  // Monitor level menu items should be in the 5000 id range
  // Queues = 5100, Logs = 5200, Server Stats = 5300, SSL = 5400, URL monitor = 5500

  {
    id: 5000,
    label: "menuitems.monitor.text",
    isTitle: true,
    access: "monitor",
  },
  {
    id: 5100,
    label: "menuitems.monitor.text",
    icon: "mdi mdi-view-week",
    link: "/monitor/queues",
    access: "queues"
  },
  {
    id: 5200,
    label: "menuitems.logs.text",
    icon: "mdi mdi-text-box-search",
    access: "logs",
    subItems: [
      {
        id: 5210,
        label: "menuitems.logs.list.api_errors",
        link: "/monitor/logs/api_errors",
        parentId: 5200,
        access: "logs_api_errors",
      },
      {
        id: 5220,
        label: "menuitems.logs.list.cron_logs",
        link: "/monitor/logs/api_cron_logs",
        parentId: 5200,
        access: "logs_api_cron_logs",
      },
      {
        id: 5230,
        label: "menuitems.logs.list.exceptions",
        link: "/monitor/logs/api_exceptions",
        parentId: 5200,
        access: "logs_api_exceptions",
      },
      {
        id: 5240,
        label: "menuitems.logs.list.api_alerts",
        link: "/monitor/logs/api_alerts",
        parentId: 5200,
        access: "logs_api_alerts",
      },
    ],
  },
  {
    id: 5300,
    label: "menuitems.server_stats.text",
    icon: "mdi mdi-view-week",
    link: "/monitor/ping",
    access: "monitor"
  },
  {
    id: 5400,
    label: "menuitems.ssl.text",
    icon: "fas fa-link",
    link: "/ssl/ssl_links",
    access: "ssl_links"
  },
  {
    id: 5500,
    label: "menuitems.url_monitor.text",
    icon: "fas fa-link",
    link: "/monitor/url_monitor",
    access: "url_monitor"
  },



  // SETUP HEADING
  // Setup level menu items should be in the 6000 id range
  // RBAC = 6100, RBAC = 6200, Sport Data Setup = 6300, Tenant Setup = 6400

  {
    id: 6000,
    label: "menuitems.setup.text",
    isTitle: true,
    access: "dashboard",
  },
    // 6100 = Tenant Dashboard RBAC Setup
  {
    id: 6100,
    label: "menuitems.rbac_setup.text",
    icon: "mdi mdi-wrench",
    access: "rbac_setup",
    subItems: [
      {
        id: 6110,
        label: "menuitems.rbac_setup.list.dashboard_access_setup",
        link: "/auth/rbac_setup/dashboard_access_setup",
        parentId: 6100 ,
        access: "dashboard_access_setup",
      },
      {
        id: 6120,
        label: "menuitems.rbac_setup.list.api_access_setup",
        link: "/auth/rbac_setup/api_access_setup",
        parentId: 6100,
        access: "api_access_setup",
      },
      {
        id: 6130,
        label: "menuitems.rbac_setup.list.application_methods",
        link: "/auth/rbac_setup/application_methods",
        parentId: 6100,
        access: "application_methods",
      },
    ]
  },
    // 6200 = Admin Dashboard RBAC Setup
  {
    id: 6200,
    label: "menuitems.rbac.text",
    icon: "mdi mdi-view-week",
    access: "rbac",
    subItems: [
      {
        id: 6210,
        label: "menuitems.rbac.list.dashboard_access",
        link: "/monitor/rbac/dashboard_access",
        parentId: 6200,
        access: "rbac_dashboard_access",
      },
      {
        id: 6220,
        label: "menuitems.rbac.list.api_access",
        link: "/monitor/rbac/api_access",
        parentId: 6200,
        access: "rbac_api_access",
      },
      {
        id: 6230,
        label: "menuitems.rbac.list.rbac_access",
        link: "/monitor/rbac/rbac_access",
        parentId: 6200,
        access: "rbac_access",
      },
    ]
  },
  {
    id: 6300,
    label: "menuitems.sport_data.text",
    icon: "uil-football",
    access: "sport_data",
    subItems: [
      {
        id: 6310,
        label: "menuitems.sport_data.list.tenant_setup",
        link: "/sport_data/tenant_setup",
        parentId: 6300,
        access: "tenant_setup",
      },
      {
        id: 6320,
        label: "menuitems.sport_data.list.tenant_tournament",
        link: "/sport_data/tenant_tournament",
        parentId: 6300,
        access: "tenant_tournament",
      },
      {
        id: 6330,
        label: "menuitems.sport_data.list.sport_team",
        link: "/sport_data/sport_team",
        parentId: 6300,
        access: "sport_team",
      },
      {
        id: 6340,
        label: "menuitems.sport_data.list.competitions",
        link: "/sport_data/competitions",
        parentId: 6300,
        access: "competitions",
      },
    ],
  },
  {
    id: 6400,
    label: "menuitems.application.text",
    icon: "uil-monitor",
    access: "application",
    subItems: [
      {
        id: 6410,
        label: "menuitems.application.list.application",
        link: "/application/access",
        parentId: 6400,
        access: "application_access",
      },
    ],
  },



  // AWS HEADING
  // AWS level menu items should be in the 10000 id range
  // AWS = 10100

  {
    id: 10000,
    label: "menuitems.aws.text",
    isTitle: true,
    access: "aws",
  },
  {
    id: 10100,
    label: "menuitems.aws.text",
    icon: "uil-cube",
    access: "aws",
    subItems: [
      {
        id: 10110,
        label: "menuitems.aws.list.applications",
        link: "/aws/applications",
        parentId: 10100,
        access: "aws_applications",
      },
      {
        id: 10120,
        label: "menuitems.aws.list.instances",
        link: "/aws/instances",
        parentId: 10100,
        access: "aws_instances",
      },
      {
        id: 10130,
        label: "menuitems.aws.list.loadbalancers",
        link: "/aws/loadbalancers",
        parentId: 10100,
        access: "aws_loadbalancers",
      },
      {
        id: 10140,
        label: "menuitems.aws.list.targetgroups",
        link: "/aws/targetgroups",
        parentId: 10100,
        access: "aws_targetgroups",
      },
      {
        id: 10150,
        label: "menuitems.aws.list.rdsinstances",
        link: "/aws/rdsinstances",
        parentId: 10100,
        access: "aws_rdsinstances",
      },
      {
        id: 10160,
        label: "menuitems.aws.list.securitygroups",
        link: "/aws/securitygroups",
        parentId: 10100,
        access: "aws_securitygroups",
      }
    ]
  },
];
